import Vue from 'vue';

const loginUrl = '/login';
const homeUrl = '/';

const loginInterrupt = function (current_url, message) {
    sessionStorage.interrupted_page = current_url;
    sessionStorage.interrupt_message = message;
    window.location.href = loginUrl;
}

const redirectToInterruptedPage = function () {
    const url = sessionStorage.interrupted_page;
    if (url && !url.includes(loginUrl)) {
        window.location.href = url
    }
    else {
        window.location.href = homeUrl;
    }
}

const wrapRequest = fn => (...params) =>
    fn(...params)
        .then(response => {
          if (!response.ok) {
                throw response;
            }
            return response.json();
        })
        .catch(error => handleError(error));

export const wrapRequestNoError = fn => (...params) =>
  fn(...params).then(response => {
    if (!response.ok) {
      console.error(response)
      throw response;
    }
    return response;
  })
    
    
const wrapRequestWithRange = fn => (...params) =>
     fn(...params)
        .then(response => {        
            if (!response.ok) {
                throw response;
            }
            let data=response.json();
            return {data:data,range:response.headers.get('Content-Range')}
        })
        .catch(error => handleError(error));

const defaultErrorMessageText = 'Unexpected error while communicating with the cloud'
export const displayErrorWithTitle = (message) => {
    Vue.$snotify.async('', '', () => new Promise((resolve, reject) => {
        reject({
          title: 'Error',
          body: message || defaultErrorMessageText,
          config: {
            closeOnClick: true
          }
        })
    }));
}
const handleError = async errorResponse => {
  if (!errorResponse.ok) {
        let data = null;
        let error = {};
        if (typeof errorResponse.text === "function") {
            let text = await errorResponse.text();
            try {
                data = JSON.parse(text);
            } catch (e) {
                data = { message: text };
            }
            switch (errorResponse.status) {
                case 403:
                    if (typeof Vue.$snotify != 'undefined') {
                        displayErrorWithTitle(data.message)
                    }
                    throw data;
                    //break;
                case 401:
                    loginInterrupt(window.location.href, 'There was an access violation caused by your request. You need to sign back in please.');
                    break;
                case 400:
                    if (data.code == "22023" && data.message.indexOf('role') >= 0
                        && data.message.indexOf('does not exist')) {
                        loginInterrupt(window.location.href, 'There was an access violation caused by your request. You need to sign back in please.');
                    }
                    else {
                        if (typeof Vue.$snotify != 'undefined') {
                            displayErrorWithTitle()
                        }
                        throw data;
                    }
                    break;
                default:
                    error = {
                        text: errorResponse.statusText,
                        code: errorResponse.status,
                        url: errorResponse.url
                    };
                    if(data?.message) {
                        try {
                            error.message = JSON.parse(data.message)
                            if(error.message?.error?.messages) {
                                try {
                                    error.message = error.message.error.messages.join(' ')
                                } catch (error) { /**/ }
                            }
                        } catch (error) {
                            error.message = data.message
                        }
                    }
                    if (typeof Vue.$snotify != 'undefined') {
                        displayErrorWithTitle(error?.message)
                    }
                    throw error
            }//switch
        }
        else{
            if (typeof Vue.$snotify != 'undefined') {
                displayErrorWithTitle()
            }
            error.message = "Cloud communication error"
            if (errorResponse.message) {
              error.text = errorResponse.message
            } 
            if (errorResponse.statusText) {
              error.text = errorResponse.statusText
            }
            if (errorResponse.status) {
              error.code = errorResponse.status
            }
            if (errorResponse.url) {
              error.url = errorResponse.url
            }

            throw error
        }

    }
};

export {
    loginInterrupt, redirectToInterruptedPage,
    wrapRequest, wrapRequestWithRange, handleError
};