import gateService from '../services/gate.service';
import { redirectToInterruptedPage } from '../services/nav';
import { getAuthUser, setAuthUser, resetAuthUser, getArtistId } from '../services/auth';

export const doorStoreModule = {
    namespaced: true,
    state: () => ({
        inProgres: false,
        pin: null
    }),
    mutations: {
        inProgress(state, yesOrNo) {
            state.inProgres = yesOrNo;
        },
        setPin(state, pin) {
            state.pin = pin;
        }
    },
    actions: {  
        setPin({ commit }, pin) {
            commit('setPin', pin);
        },
        resetState({ commit }) {
            commit('resetState');
        }
    },

};
