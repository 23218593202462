import Vue from 'vue'
import Vuex from 'vuex'

import { authStoreModule } from './auth.module';
import { doorStoreModule } from './door.module';

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    auth: authStoreModule,
    door: doorStoreModule,
    layout: {
      state: {
        barColor: 'rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)',
        barImage: '/drawer.jpg',
        drawer: null,
      },
      mutations: {
        SET_BAR_IMAGE (state, payload) {
          state.barImage = payload
        },
        SET_DRAWER (state, payload) {
          state.drawer = payload
        },
        SET_SCRIM (state, payload) {
          state.barColor = payload
        },
      },
      actions: {

      }
    }
  }
})
