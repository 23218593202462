import Vue from 'vue'
import VueIziToast from 'vue-izitoast';
import App from './App.vue'
import { router } from './router'
import store from './stores/store'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import 'izitoast/dist/css/iziToast.css';
import cloudinary from 'cloudinary-vue';
import VueGtag from "vue-gtag";
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import VueMoment from "vue-moment";
import moment from "moment-timezone";

Vue.use(Datetime);
Vue.component('datetime', Datetime);

moment.tz.guess();
Vue.use(VueMoment, { moment });

Vue.use(cloudinary, {
  configuration: {
    cloudName: "dimyv3wy5",
  }
});

Vue.use(TiptapVuetifyPlugin, {
  vuetify, // same as "vuetify: vuetify"
  iconsGroup: 'mdi'
})

Vue.config.productionTip = false

import { Vue as VueIntegration } from '@sentry/integrations';
import VueCookies from 'vue-cookies';

import * as Sentry from '@sentry/browser';
if (process.env.VUE_APP_ENABLE_SENTRY == "true") {
  Sentry.init({
    dsn: 'https://3f03509069af447b85cf25548826368f@o390816.ingest.sentry.io/5235924',
    integrations: [new VueIntegration({Vue, attachProps: true, logErrors: true})],
  });
}

if (process.env.NODE_ENV === 'production') {
  Vue.use(VueGtag, {
    config: { id: "UA-168834781-1" }
  }, router);
}

Vue.config.errorHandler = (err, vm, info) => {
  if (err.name === "ValidationError") return;
  console.error(err);
};

import VueMeta from 'vue-meta';
Vue.use(VueMeta);

Vue.use(VueCookies);

Vue.use(VueIziToast);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
