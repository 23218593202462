import { wrapRequest } from './nav';

const doLogin = wrapRequest((appId, username, password) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                email: username,
                pass: password, app_id: appId
            })
    };
    return fetch(`${process.env.VUE_APP_API_URL}/login`, requestOptions)
});

const forgotPassword = wrapRequest((email) => {
  const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        returnUrl: window.location.host + "/gate/reset-password"
      })
  };
  return fetch(`${process.env.VUE_APP_API_URL}/forgotPassword`, requestOptions)
});

const resetPassword = wrapRequest((password, token) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify({
          password
        })
    };
    return fetch(`${process.env.VUE_APP_API_URL}/changeUserPassword`, requestOptions)
});

const doLoginRefresh = wrapRequest((auth, artistId) => {
  const requestOptions = {
    method: 'POST',
    headers: { 
      'Content-Type': 'application/json', Authorization: 'Bearer ' + auth
    },
    body: JSON.stringify({artistId})
  }
  return fetch(`${process.env.VUE_APP_API_URL}/refreshLogin`, requestOptions)
})

const doLogout = () => {
    // remove user from local storage to log user out
    localStorage.removeItem('user_token');
}

export default {
  forgotPassword,
  resetPassword,
  doLogin,
  doLoginRefresh,
  doLogout,
};
