import gateService from '../services/gate.service';
import { redirectToInterruptedPage } from '../services/nav';
import { getAuthUser, setAuthUser, resetAuthUser, getArtistId, getArtist } from '../services/auth';

export const authStoreModule = {
    namespaced: true,
    state: () => ({
        inProgres: false,
        userAuth: getAuthUser() || {},
        signInMessage: null,
        errorMessage: null,
        artist: {},
        artistId: getArtistId() || null,
    }),
    mutations: {
        inProgress(state, yesOrNo) {
            state.inProgres = yesOrNo;
        },
        setUser(state, user) {
            state.userAuth = user;
        },
        setArtist(state, artist) {
            state.artist = artist;
        },
        setArtistId(state, artistId) {
            state.artistId = artistId;
        },
        loginSuccess(state, user) {
            state.inProgres = false;
            state.errorMessage = null;
            state.userAuth = user;        
            setAuthUser(user);
        },
        loginFailure(state, errorMessage) {
            state.inProgres = false;
            state.userAuth = {};
            state.errorMessage = errorMessage;
        },
        prepareWithMessage(state, message) {
            state.inProgres = false;
            state.userAuth = {};
            state.signInMessage = message;
        },
        logout(state) {
            state.inProgres = false;
            state.userAuth = {};
            resetAuthUser();
        },
        resetState(state) {
            state.inProgres = false;
            state.userAuth = getAuthUser() || {};
            state.artistId = getArtistId() || null,
            state.errorMessage = null;
            state.signInMessage = null;
        }
    },
    actions: {

        login: async ({ commit }, { appId, username, password }) => {

            try {                
                commit('inProgress', true);
                const user = await gateService.doLogin(appId, username, password);
                                
                if (user.auth) {
                    commit('loginSuccess', user);                    
                    redirectToInterruptedPage();
                } else {
                  commit('inProgress', false);
                  commit('loginFailure', user.message);
                }
            } catch (err) {
                commit('inProgress', false);
                commit('loginFailure', err.message);
            }
        },

        loginRefresh: async ({ commit }, { auth, artistId }) => {

            try {
                const user = await gateService.doLoginRefresh(auth, artistId)
                if (user.auth) {
                    commit('loginSuccess', user);
                } else {
                  commit('loginFailure', user.message);
                }
            } catch (err) {
                commit('inProgress', false);
                commit('loginFailure', err.message);
            }
        },
        setUser({ commit }, user) {
            commit('setUser', user);
        },
        setArtist: async({ commit }, artistId) => {
            try {
                const artists = await getArtist(artistId)
                if (artists.length){
                    commit('setArtist', artists[0]);
                }
            } catch (err) {
                //handle?
            }
        },
        setArtistId({ commit }, artistId) {
            commit('setArtistId', artistId);
        },
        logout({ commit }) {
            commit('logout');
        },
        prepare({ commit }, message) {
            commit('prepareWithMessage', message);
        },
        resetState({ commit }) {
            commit('resetState');
        }
    },

};
