import Vue from 'vue'
import Router from 'vue-router'
import store from '@/stores/store'
import { getAuthUser, getArtistId } from '@/services/auth';

Vue.use(Router)

const authCheck = (to, from, next) => {
  if (!to.matched.length) {
      location.href = to.path;
  }
  const userAuth = getAuthUser();
  const artistAuth = getArtistId();
  store.dispatch("auth/resetState");

  if (!userAuth) {
    next("/gate/login")
  }
  else {
    const artistId = to.query.artistId || artistAuth
    if (!artistId) {
      next("/gate/login")
    } 
    else if (to.query.artistId) {
      store.dispatch("auth/setArtistId", to.query.artistId);
      router.replace({'query': null});
    }
    else {
      next()
    }
  }
};

const authCheckUser = (to, from, next) => {
  if (!to.matched.length) {
      location.href = to.path;
  }
  const userAuth = getAuthUser();
  store.dispatch("auth/resetState");

  if (!userAuth) {
    sessionStorage.interrupted_page = location.href;
    next("/gate/login-user")
  }
  else {
    next()
  }
};

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/Dashboard'
    },
    {
      path: '/gate',
      redirect: { name: 'LoginPage' },
      name: 'Gate',
      component: () => import('@/layouts/frontend/Gate'),
      children: [
        {
            path: 'login',
            name: 'LoginPage',
            component: () => import('@/views/pages/gate/login')
        },
        {
            path: 'login-user',
            name: 'LoginUserPage',
            component: () => import('@/views/pages/gate/login-user')
        },
        {
            path: 'forgot-password',
            name: 'ForgotPassword',
            component: () => import('@/views/pages/gate/forgot.password')
        },
        {
            path: 'reset-password',
            name: 'ResetPassword',
            component: () => import('@/views/pages/gate/reset.password')
        },
        {
            path: 'reset-password/openstage',
            name: 'ResetPasswordOpenstage',
            component: () => import('@/views/pages/gate/reset.password')
        }
      ]
    },
    {
      path: '/loggedOut',
      redirect: to => {
        var path = $cookies.get("returnToPath");
        return {path:path}
      }
    },
    // Doorman ticket scanner (no auth)
    {
      path: '/ticket/:boxOfficeId/:ticketId',
      component: () => import('@/views/door/Ticket'),
    },
    {
      path: '/BoxOffice',
      component: () => import('@/views/boxOffice/Index'),
      children: [
        {
          path: 'Location',
          component: () => import('@/views/boxOffice/Location'),
        },
        {
          path: ':boxOfficePath',
          component: () => import('@/views/boxOffice/BoxOffice'),
        },
        {
          path: ':boxOfficePath/:eventPath',
          component: () => import('@/views/boxOffice/Event'),
        },
        {
          path: ':boxOfficePath/:eventPath/:eventTimeId',
          component: () => import('@/views/boxOffice/Event'),
        },
        {
          path: ':boxOfficePath/:eventPath/:eventTimeId/calendar.ics',
          component: () => import('@/views/boxOffice/EventIcsCalendar'),
        },
        {
          path: ':boxOfficePath/:eventPath/:eventTimeId/calendar.google',
          component: () => import('@/views/boxOffice/EventGoogleCalendar'),
        },
      ]
    },
    {
      path: '/Dashboard',
      name: 'Dashboard',
      beforeEnter: authCheck,
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          path: '',
          component: () => import('@/views/dashboard/BoxOffices'),
        },
        {
          path: 'BoxOffices',
          component: () => import('@/views/dashboard/BoxOffices'),
        },
        {
          path: 'Events/:id',
          component: () => import('@/views/dashboard/Events'),
        },
        {
          path: 'BoxOfficeSettings/:id',
          component: () => import('@/views/dashboard/BoxOfficeSettings'),
        },
        
        // needed for callbacks
        {
          path: 'BoxOfficeSettings',
          component: () => import('@/views/dashboard/BoxOfficeSettings'),
        },
        {
          path: 'Users/:id',
          component: () => import('@/views/dashboard/Users'),
        },
        {
          path: 'Wizard/:id',
          component: () => import('@/views/dashboard/Wizard'),
        },

        // needed for callbacks
        {
          path: 'Wizard',
          component: () => import('@/views/dashboard/Wizard'),
        },
        {
          path: 'BoxOfficeSales/:id',
          component: () => import('@/views/dashboard/BoxOfficeSales'),
        },
        {
          path: 'Profile',
          component: () => import('@/views/dashboard/Profile'),
        },
        {
          path: 'AllSearch/:id',
          component: () => import('@/views/dashboard/AllSearch'),
        },
        {
          path: 'EventDetails/:id',
          component: () => import('@/views/dashboard/EventDetails'),
        },
        {
          path: 'EventReports/:id',
          component: () => import('@/views/dashboard/EventReports'),
        },
        {
          path: 'TicketTypes/:id',
          component: () => import('@/views/dashboard/TicketTypes'),
        },
        {
          path: 'Products/:id',
          component: () => import('@/views/dashboard/Products'),
        },
        {
          path: 'EventTimes/:id',
          component: () => import('@/views/dashboard/EventTimes'),
        },
        {
          path: 'TicketType/:id',
          component: () => import('@/views/dashboard/TicketType'),
        },
        {
          path: 'EventTime/:id',
          component: () => import('@/views/dashboard/EventTime'),
        },
        {
          path: 'Product/:id',
          component: () => import('@/views/dashboard/Product'),
        },
        {
          path: 'Order/:id',
          component: () => import('@/views/dashboard/Order'),
        },
        {
          path: 'Ticket/:id',
          component: () => import('@/views/dashboard/Ticket'),
        },
        {
          path: 'OrderItem/:id',
          component: () => import('@/views/dashboard/OrderItem'),
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          path: '',
          component: () => import('@/views/pages/Error'),
        },
      ],
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export { router }